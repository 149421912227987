import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/master-user", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/master-app", state: "formElementsMenuOpen" },
      { path: "/transaksi", state: "Transaksi" },
      { path: "/cashback", state: "Cashback" },
      { path: "/validation", state: "Validation" },
      { path: "/bank-validasi", state: "Bank_transfer" },
      { path: "/input", state: "Input_paket" },

      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a
              href="!#"
              className="nav-link"
              onClick={(evt) => evt.preventDefault()}
            >
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">
                  <Trans>{localStorage.getItem("level")}</Trans>
                </span>
                <span className="text-secondary text-small">
                  <Trans>{localStorage.getItem("nama")}</Trans>
                </span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
              <i className="mdi mdi-chart-arc menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/chart") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/chart">
              <span className="menu-title">
                <Trans>Dashboard Chart</Trans>
              </span>
              <i className="mdi mdi-chart-line menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/master-user") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.basicUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("basicUiMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>MASTER USER</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-user/admin")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-user/admin"
                  >
                    <Trans>Admin</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-user/kurir")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-user/kurir"
                  >
                    <Trans>Kurir</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-user/olshop")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-user/olshop"
                  >
                    <Trans>Olshop / Mitra</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/master-app") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("formElementsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>MASTER APP</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-chemical-weapon menu-icon"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-app/branch")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-app/branch"
                  >
                    <Trans>Branch</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-app/banner-informasi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-app/banner-informasi"
                  >
                    <Trans>Banner</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/master-app/ongkir")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/master-app/ongkir"
                  >
                    <Trans>Price List</Trans>
                  </Link>
                </li>
               
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/validation") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.Validation ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("Validation")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>SETORAN</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-cellphone-link menu-icon"></i>
            </div>
            <Collapse in={this.state.Validation}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/validation/delivery")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/validation/delivery"
                  >
                    <Trans>Validasi Kurir</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/validation/cabang")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/validation/cabang"
                  >
                    <Trans>Validasi Cabang</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          {/*
          <li className={ this.isPathActive('/invoice') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.Invoice ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('Invoice') } data-toggle="collapse">
              <span className="menu-title"><Trans>INVOICE</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-chemical-weapon menu-icon"></i>
            </div>
            <Collapse in={ this.state.Invoice }>
              <ul className="nav flex-column sub-menu">
                
                <li className="nav-item"> <Link className={ this.isPathActive('/Invoice/tagihan') ? 'nav-link active' : 'nav-link' } to="/invoice/tagihan"><Trans>Tagihan</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/Invoice/pembayaran') ? 'nav-link active' : 'nav-link' } to="/invoice/pembayaran"><Trans>Pembayaran</Trans></Link></li>
          
               
              </ul>
            </Collapse>
          </li>
         */}

          <li
            className={
              this.isPathActive("/transaksi") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.Transaksi ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("Transaksi")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>TRANSAKSI</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-chemical-weapon menu-icon"></i>
            </div>
            <Collapse in={this.state.Transaksi}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/transaksi/ar-terima")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transaksi/ar-terima"
                  >
                    <Trans>AR - Terima</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/transaksi/laporan-tf")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transaksi/laporan-tf"
                  >
                    <Trans>Laporan Pickup</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/transaksi/invoice")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transaksi/invoice"
                  >
                    <Trans>Invoice</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/transaksi/laporantf-hp")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/transaksi/laporantf-hp"
                  >
                    <Trans>Laporan TF H(+)</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/bank-validasi")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.Bank_transfer ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("Bank_transfer")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>BANK VALIDASI</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-bank menu-icon"></i>
            </div>
            <Collapse in={this.state.Bank_transfer}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/bank-validasi/penerima")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/bank-validasi/penerima"
                  >
                    <Trans>Penerima (Masuk)</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/bank-validasi/ar-terima")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/bank-validasi/ar-terima"
                  >
                    <Trans>AR-Terima (Masuk) </Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/input") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.Input_paket ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("Input_paket")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>PAKET</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-dropbox menu-icon"></i>
            </div>
            <Collapse in={this.state.Input_paket}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/input/paket")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/input/paket"
                  >
                    <Trans>PICKUP (MASUK)</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/input/kendala")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/input/kendala"
                  >
                    <Trans>KENDALA (MASUK)</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/input/maping")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/input/maping"
                  >
                    <Trans>DELIVERY (KELUAR)</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/input/return")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/input/return"
                  >
                    <Trans>RETURN (KELUAR)</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/input/integrasi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/input/integrasi"
                  >
                    <Trans>INTEGRASI (KELUAR)</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/cashback") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.Cashback ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("Cashback")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>CASHBACK</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-wallet menu-icon"></i>
            </div>
            <Collapse in={this.state.Cashback}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/cashback/buat-pengajuan")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/cashback/buat-pengajuan"
                  >
                    <Trans>Buat Pengajuan</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/cashback/proses-pencairan")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/cashback/proses-pencairan"
                  >
                    <Trans>Proses Pencairan</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/rekap") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/rekap">
              <span className="menu-title">
                <Trans>REKAP</Trans>
              </span>
              <i className="mdi mdi-clipboard-alert menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/tracking") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/tracking">
              <span className="menu-title">
                <Trans>TRACKING</Trans>
              </span>
              <i className="mdi mdi-clipboard-alert menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/report") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/report">
              <span className="menu-title">
                <Trans>REPORT</Trans>
              </span>
              <i className="mdi mdi-clipboard-alert menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
